.buttonGray {
  border-color: #d9d9d9;
}
.buttonGray:hover {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}
.buttonGray:active {
  border-color: #c2c2c2 !important;
  background-color: #a4a4a4 !important;
}
.buttonGreen {
  border-color: #15b789;
}
.buttonGreen:hover {
  border-color: #10946f;
  background-color: #10946f;
}
.buttonGreen:active {
  border-color: #10946f !important;
  background-color: #0d7054 !important;
}
.user-profile input.is-invalid, #change-password input.is-invalid {
  border-color: #dc3545;
}
.user-profile input.is-invalid:focus, #change-password input.is-invalid {
  outline-color:#dc3545;
  /* border-color: #dc3545; */
}

.upload-btn-wrapper {
  position: relative;
	overflow: hidden;
  transition: all .3s ease;
  height: 10vmax;
}

.upload-btn-wrapper:hover {
	cursor: pointer;
}

.profile-pic {
  height: 100%;
  width: 100%;
  transition: all .3s ease;
  
}
.profile-pic:after{
  /* font-family: FontAwesome;
  content: "\f007";
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 190px;
  background: #ecf0f1;
  color: #34495e;
  text-align: center; */
}

.upload-btn-wrapper .upload-btn {
  /* border: none; */
  opacity: 0.75;
  /* background-color: transparent; */
  color: #f5a81e;
  height: 100%;
  width: 100%;
  /* font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0; */
}

.upload-btn-wrapper .upload-btn svg{
  position: absolute;
  font-size: 2vmax;
  bottom: 5px;
  right: 0;
  text-align: center;
  opacity: .5;
  transition: all .3s ease;
  color: #f5a81e;
}

.upload-btn-wrapper:hover .upload-btn svg{
  opacity: 0.9 !important;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}
.forgotPwd-btn {
  color:#39c288;
  background:none;
  border:none !important;
}
.forgotPwd-btn:hover {
  color:#43f1a9;
  background: none;
}
.forgotPwd-btn:not(:disabled):not(.disabled):active {
  color:#34b982;
  background: none;
  border: none;
  outline: none !important;
  box-shadow: none !important;
}
.forgotPwd-btn:focus {
  outline: none;
  box-shadow: none;
}
@media screen and (max-width: 575.98px) {
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:.5em;
  }
  .user-profile div.col-12:nth-child(1) a {
    margin-left: .5em;
  }
  .user-profile .user-image {
    justify-content: center!important;
    padding-top: 1rem;
  }
  .user-profile .user-image img {
    width: 7rem;
    height: 7rem;
  }
  .edit-btn-group {
    display: flex;
    justify-content: center;
  }
  #change-password {
    margin-left: auto;
    margin-right: auto;
  }

  .upload-btn-wrapper .upload-btn {
    left: 20%;
    top: 45%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .edit-btn-group {
    display: flex;
    justify-content: center;
  }
  .user-profile .user-image {
    padding-top: 1rem;
  }
  .user-profile .user-image img {
    width: 7rem;
    height: 7rem;
  }
  .upload-btn-wrapper .upload-btn {
    left: 20%;
    top: 45%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:1.2em;
  }
  .edit-btn-group {
    display: flex;
    justify-content: center;
  }
  .user-profile .user-image {
    padding-top: 1rem;
  }
  .user-profile .user-image img {
    width: 9rem;
    height: 9rem;
  }
  .upload-btn-wrapper .upload-btn {
    left: 25%;
    top: 60%;
  }
}

@media (min-width: 992px)  {
  
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:1.3em;
  }
  .user-profile .user-image{
    padding: 1.3em 0;
  }
  
  .user-profile .user-image img {
    width: 9rem;
    height: 9rem;
  }
}
