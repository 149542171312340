@import url(https://fonts.googleapis.com/css?family=Kanit&display=swap);
@import url(/node_modules/video-react/dist/video-react.css);
.navbar .navbar-collapse {
  margin-left: .5em;
}

@media screen and (max-width: 575.98px) {
  #login-form .modal-body{
    padding: 1em;
  }
  .navbar .navbar-brand  {
    margin: .75em 0 .75em .25em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .navbar .navbar-brand  {
    margin: .5em 0 .5em .5em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .navbar .navbar-brand  {
    margin: .5em 0 .5em .5em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
}

@media (min-width: 992px)  {
  .navbar .navbar-brand img {
    height: 60px;
  }

  #basic-navbar-nav {
    margin-left: 3em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #basic-navbar-nav form.form-inline .form-group {
    display: flex;
    justify-content: space-between;
  }
  #basic-navbar-nav form.form-inline .form-group .search-btn{
    width: 10em;
    display: flex;
    justify-content: space-between;
  }
  #basic-navbar-nav form.form-inline .form-group .btn-toolbar {

  }
}


@media (min-width: 1400px)  {
  .navbar .navbar-brand  {
    margin: .5em 0 .5em .5em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
  
  #basic-navbar-nav {
    margin-left: 7em;
  }
  #basic-navbar-nav form.form-inline .form-group .search-btn{
    width: 12em;
  }
  
}

.footer  {
  padding: 2em auto;
}
.footer .jumbotron {
  border-radius: 2px;
}
.img-w {
  width: 20%;
}
.img-margin-right {
  margin-right: 10px;
  margin-bottom: 10px;
}
#dl-chn-head {
  margin-bottom: .75em;
}
.about-us {
  width: 100%;
}
.about-us .list-group {
  list-style-type: none;
  font-size: .95em;
}
.about-us .list-group .obec-brand {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}
.about-us .list-group .obec-brand img {
  padding-right: 1em;
  height: 100px;
}
.about-us .list-group .obec-brand div {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-self: center;
}
.about-us .site-map p, .about-us .contact-us p, .about-us .facebook-fanpage p {
  font-weight: bold;
}
.about-us .site-map li {
  margin-bottom: .52rem;
}
.about-us .contact-us li img {
  margin-right: .9em;
}
.contact-us ul li span {
  margin-bottom: .5rem;
}
@media screen and (max-width: 575.98px) {
  .about-us {
    /* margin-left: 2em !important; */
  }
  .about-us .list-group .obec-brand img {
    height: 120px;
  }
  .about-us .obec-info, .about-us .contact-us, .about-us .facebook-fanpage {
    margin-bottom: 2em;
  }
  .about-us .contact-us, .about-us .facebook-fanpage  {
    margin-left: 1rem;
  }
  .about-us .contact-us li img {
    margin-right: 3em;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .about-us {
    margin-left: 2em !important;
  }
  .about-us .list-group .obec-brand img {
    height: 120px;
  }
  .about-us .obec-info, .about-us .contact-us, .about-us .facebook-fanpage {
    margin-bottom: 2em;
  }
  .about-us .contact-us, .about-us .facebook-fanpage  {
    margin-left: 1rem;
  }
  .about-us .contact-us li img {
    margin-right: 3em;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .about-us .site-map, .about-us .facebook-fanpage  {
    display: none;
  }
  .about-us {
    margin-left: 1em !important;
    padding-bottom: 3em;
  }
}

@media (min-width: 992px)  {
  .about-us {
    /* margin-left: 1em !important; */
    padding-bottom: 2em;
  }
}

.footer .copyright{
  background-color: #c9c9c9;
  color: #333;
}

.contentActiveBar {
  background-color: rgb(64, 223, 175);
  height: 8px;
}

.contents-list a .card-text {
  color: black;
}

.contents-list a,
.contents-list a:hover {
  text-decoration: none;
}

.card-text {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 20px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
}

.imgOrg {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
}

@media screen and (device-aspect-ratio: 2/3) {
  .imgType1 {
    height: 191px !important;
  }

  .imgType3 {
    height: 116px !important;
  }
}

/* iphone 5/5s */
@media screen and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  .imgType1 {
    height: 153px !important;
  }

  .imgType3 {
    height: 86px !important;
  }
}

@media screen and (device-aspect-ratio: 71/40) and (orientation: landscape) {
  .imgType1 {
    height: 319px !important;
  }

  .imgType3 {
    height: 179px !important;
  }
}

/* iphone 6/7/8 */
@media screen and (device-aspect-ratio: 375/667) and (orientation: portrait) {
  .imgOrg {
    height: 143px !important;
  }

  .imgType1 {
    height: 191px !important;
  }

  .imgType3 {
    height: 107px !important;
  }
}

@media screen and (device-aspect-ratio: 667/375) and (orientation: landscape) {
  .imgOrg {
    height: 105px !important;
  }

  .imgType1 {
    height: 140px !important;
  }

  .imgType3 {
    height: 79px !important;
  }
}

/* iphone 6/7/8 plus */
@media screen and (device-aspect-ratio: 414/736) and (orientation: portrait) {
  .imgType1 {
    height: 216px !important;
  }

  .imgType3 {
    height: 116px !important;
  }
}

@media screen and (device-aspect-ratio: 736/414) and (orientation: landscape) {
  .imgType1 {
    height: 140px !important;
  }

  .imgType3 {
    height: 79px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 575.9px) and (orientation: portrait) {
  .imgOrg {
    height: 115px;
  }

  .imgType1 {
    height: 263px;
  }

  .imgType3 {
    height: 148px;
  }
}

@media screen and (max-width: 575.9px) and (orientation: landscape) {
  .imgOrg {
    height: 239px;
  }

  .imgType1 {
    height: 263px;
  }

  .imgType3 {
    height: 148px;
  }
}

@media (min-width: 576px) and (max-width: 767.9) {
  .imgType1 {
    height: 140px !important;
  }

  .imgType3 {
    height: 79px !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.9px) {
  .imgType1 {
    height: 120px !important;
  }

  .imgType3 {
    height: 68px !important;
  }
}

/* Large devices (desktops, 992px and up) */
/* iPad Pro 1024x1366 */
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .imgOrg {
    height: 130px !important;
  }

  .imgType1 {
    height: 173px !important;
  }

  .imgType3 {
    height: 98px !important;
  }
}

/* iPad Pro 1366x1024 */
@media screen and (min-width: 1025px) and (max-width: 1199.9px) {
  .imgOrg {
    height: 130px !important;
  }

  .imgType1 {
    height: 173px !important;
  }

  .imgType3 {
    height: 98px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1365.9px) {
  .imgOrg {
    height: 130px !important;
  }

  .imgType1 {
    height: 213px !important;
  }

  .imgType3 {
    height: 120px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1366px) {
  .imgOrg {
    height: 160px !important;
  }

  .imgType1 {
    height: 213px !important;
  }

  .imgType3 {
    height: 120px !important;
  }
}
body {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
}

/* Override Bootrstrap */
a {
  color: #313131;
}

a:hover {
  color: #495057;
  text-decoration: none;
}


/* @import "../node_modules/video-react/dist/video-react.css";  */

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 3vmin;
  padding-bottom: 3vmin;
  width: 100%;
  margin: 0;
}

.contentBook {
  background-color: #42dfaf;
  background-image: url(/static/media/bg-book.293d443b.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.contentVdo {
  background-color: #f6fbff;
  background-image: url(/static/media/video_bg.3b595b80.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.contentRecomend {
  background-color: #7ad3ff;
  background-image: url(/static/media/rec_bg.0d7131e7.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.contentLatest {
  background-color: #fdd5d2;
  background-image: url(/static/media/latest_bg.d76fefbc.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.contentButton {
  width: 100%;
  border-radius: 1vmax;
  align-items: center;
  /* background-color: #42dfaf; */
  background-image: -webkit-gradient(linear, right top, left top, from(#cccccc), to(#c4c4c4));
  background-image: linear-gradient(to left, #cccccc, #c4c4c4);
  /* background-image: linear-gradient(to left,#40dfaf,#17b78a); */
  border: none;
  vertical-align: middle;
  padding-top: 1vmax;
  padding-bottom: 1vmax
}

.contentButton img {
  margin-top: 10%;
  width: 35%;
  height: auto;
  margin-bottom: 10%;
}

.contentButton:focus,
.contentButton.activefocus {
  box-shadow: none;
}

.contentButton.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.contentButton:hover {
  /* background-image: linear-gradient(to bottom left,#40dfaf,#17b78a); */
  background-image: -webkit-gradient(linear, right top, left bottom, from(#40dfaf), to(#17b78a));
  background-image: linear-gradient(to bottom left, #40dfaf, #17b78a);
}

.contentButton.active {
  background-image: -webkit-gradient(linear, right top, left bottom, from(#40dfaf), to(#17b78a));
  background-image: linear-gradient(to bottom left, #40dfaf, #17b78a);
}

.colCenter {
  text-align: center
}

.hitList {
  text-align: center;
  height: 160px;
  /* width: 150px; */
  /* margin: 5px; */
  overflow: hidden;
  padding: 0;
  position: relative;
}

.hitListText {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  background-color: #313131;
  opacity: 0.7;
  color: white;
  padding: 5px;
  text-align: left;
}

.borderIcon {
  background-color: #ffffff86;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 8px;
  margin: 0 auto;
}

.borderIcon img {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.borderIconRed {
  background-color: #efeff0;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 8px;
  margin: 0 auto;
}

.borderIconRed img {
  background-color: #ff5759;
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.borderIconBlue {
  background-color: #1d7be04d;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 8px;
  margin: 0 auto;
}

.borderIconBlue img {
  background-color: #1d7be0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ribbon2 {
  width: 40px;
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  border-top-left-radius: 3px;
  background: #1d7be0;
  /* background-image: linear-gradient(to top left,#1d7be0,#2d87e7); */
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  z-index: 5;
}

.ribbon2:before,
.ribbon2:after {
  content: "";
  position: absolute;
}

.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -16px;
  left: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 17px solid #1d7be0;
}

.buttonFilter {
  padding: 1;
  background-color: #fec35c;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
  border: none;
}

.buttonFilter:hover {
  background-color: #f3a31a;
}

.buttonFilter:active {
  background-color: #fec35c !important;
  box-shadow: 0 0 0 0.2rem rgba(245, 182, 46, 0.5)
}

.buttonFilter:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 182, 46, 0.5)
}

.buttonFilter img {
  height: 20px;
}

.buttonSort {
  padding: 1;
  text-align: center;
  background-color: #ffffff;
  color: #787a7a;
  border-radius: 20px;
  font-size: 14px;
  border: none;
}

.buttonSort:hover {
  background-color: #faca77;
  color: #ffffff;
  border: none;
}

.buttonSort:focus {
  background-color: #fec35c;
  color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.buttonSortActive {
  padding: 1;
  text-align: center;
  background-color: #fec35c;
  color: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  border: none;
}

.buttonSortActive:hover {
  background-color: #fec35c;
  color: #000000;
  border: none;
}

.buttonSortActive:focus {
  background-color: #fec35c;
  color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.imgList {
  margin-bottom: 20px;
}

.imgList img {
  width: 100%;
}

.imgList div:hover {
  box-shadow: 0 0 3px #7a7a7a;
}

.buttonPage {
  padding: 1;
  text-align: center;
  background-color: #ffffff;
  color: #787a7a;
  font-size: 14px;
  border: 1px solid #787a7a;
}

.buttonPage:hover {
  background-color: #fec35c;
  color: #ffffff;
}

.buttonPage:focus {
  background-color: #fec35c;
  color: #ffffff;
  box-shadow: none;
}

.buttonPageActive {
  padding: 1;
  text-align: center;
  background-color: #fec35c;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #787a7a;
}

.buttonPageActive:hover {
  background-color: #fec35c;
  color: #ffffff;
}

.buttonPageActive:focus {
  background-color: #fec35c;
  color: #ffffff;
  box-shadow: none;
}

.checkBox {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.checkBox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 18px;
  width: 18px;
  -webkit-transition: all 0.12s ease-out 0s;
  transition: all 0.12s ease-out 0s;
  background: #fff;
  border: 1px solid #cbd1d8;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  /* z-index: 1000; */
  /* border-radius: 50%; */
}

.checkBox input:hover {
  background: #9faab7;
}

.checkBox input:checked {
  background: #07b169;
}

.checkBox input:checked::before {
  height: 18px;
  width: 18px;
  position: absolute;
  content: '✓';
  display: inline-block;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin-left: 0;

}

.checkBox input:checked::after {
  -webkit-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #07b169;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}

.nav-tabs .nav-link {
  color: #9faab7;
  /* font-size: 2vmin; */
}

.nav-tabs .nav-link.active {
  color: #07b169;
  background-color: #fff;
  border-color: #fff #fff #07b169;
  border-bottom-width: 5px;
}


.radiusGreen {
  padding: 5px 10px;
  background-color: #07b169;
  box-shadow: 1px 1px 2px #dfdfdf;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.radiusGreen:hover {
  box-shadow: 0 0 5px #0cd682;
}

.radiusGreen:active {
  box-shadow: inset 0 0 5px #0cd682;
}

.radiusGreenThin {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #2ac79a;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  /* font-size: 90%; */
  white-space: nowrap;
}

.outlineGreen {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #07b169;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #07b169;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  font-size: 90%;
  white-space: nowrap;
}

.radiusPink {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffb0ab;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusOrange {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #fec35c;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusBlue {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #7ad3ff;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusPurple {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #cc66ff;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusFlash {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffc3ab;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusApp {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #fe6d6b;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusWhite {
  padding: 5px 10px;
  margin: 0 2px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 20px;
  box-shadow: 1px 1px 2px #dfdfdf;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.radiusWhite:hover {
  box-shadow: 0 0 5px #a6a8a8;
}

.radiusWhite:active {
  box-shadow: inset 0 0 5px #a6a8a8;
}

.radiusGray {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #e1e1e1;
  color: #ff4546;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.radiusReadOnly {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #e1e1e1;
  color: #bfbfbf;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
  cursor: no-drop;
  white-space: nowrap;
}

.radiusStarOrange {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffefca;
  color: #ff8c20;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusGrayLinear {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #e1e1e1;
  background-image: -webkit-gradient(linear, left top, right top, from(#e1e1e1), to(#bdbdbd));
  background-image: linear-gradient(to right, #e1e1e1, #bdbdbd);
  color: #ff4546;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  cursor: pointer;
  white-space: nowrap;
}

.radiusGrayLinear:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e1e1e1), to(#bdbdbd));
  background-image: linear-gradient(to bottom, #e1e1e1, #bdbdbd);
}

.radiusGrayLinear:active {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#e1e1e1), to(#bdbdbd));
  background-image: linear-gradient(to top, #e1e1e1, #bdbdbd);
}

.radiusGreenLinear {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #40dfaf;
  background-image: -webkit-gradient(linear, left top, right top, from(#40dfaf), to(#17b78a));
  background-image: linear-gradient(to right, #40dfaf, #17b78a);
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  cursor: pointer;
  white-space: nowrap;
}

.radiusGreenLinear:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#40dfaf), to(#17b78a));
  background-image: linear-gradient(to bottom, #40dfaf, #17b78a);
}

.radiusGreenLinear:active {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#40dfaf), to(#17b78a));
  background-image: linear-gradient(to top, #40dfaf, #17b78a);
}

.carousel-caption {
  top: 10%;
  left: 3%;
  right: unset;
  bottom: unset;
  text-align: left;
  border-left: 10px solid #ffffff;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: unset;
  z-index: 0;
}

.carousel-caption h3 {
  font-size: 6vmin;
}

.carousel-item img {
  width: 100%;
}

.carousel-indicators li {
  height: 2vmin;
  width: 2vmin;
  border-radius: 2vmin;
  background: #ffffff;
  border: none;
  opacity: 0.5;
  box-shadow: 0 0 5px #929191;
}

.carousel-indicators li:active {
  opacity: 1;
}

.selectList {
  /* border-bottom: 1px solid #cccccc; */
  width: 100%;
  /* padding-bottom: 5px; */
}

.buttonRed {
  padding-left: 3vmax;
  padding-right: 3vmax;
  padding-top: 0.7vmax;
  padding-bottom: 0.7vmax;
  background-color: #fed7d8;
  color: #fe7776;
  text-align: center;
  border-radius: 5px;
  /* font-size: 1.5vmin; */
  cursor: pointer;
  border-color: #fe7776;
}

.buttonBlue {
  padding-left: 3vmax;
  padding-right: 3vmax;
  padding-top: 0.7vmax;
  padding-bottom: 0.7vmax;
  background-color: #daebff;
  color: #4891e3;
  text-align: center;
  border-radius: 5px;
  /* font-size: 1.5vmin; */
  cursor: pointer;
  border-color: #4891e3;
}

.buttonWhite {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #ffffff;
  color: #ff5857;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.buttonGreen {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #15b789;
  color: #ffffff;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.buttonGray {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #d9d9d9;
  color: #ffffff;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.buttonThin {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #0000001e;
  color: #ffffff;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.headerProfile {
  background-color: #36d4a5;
  padding: 20px;
  background-image: url(/static/media/bg-book.293d443b.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff
}

.btHoverGreen {
  margin-right: 20px;
  color: #9d9d9d;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.btHoverGreenActive {
  background-color: #dcfaee;
  color: #07b169;
  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.btHoverGreen:hover {
  background-color: #dcfaee;
  color: #07b169;
}

.inputProfile {
  width: 100%;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
}

.modal-sm {
  max-width: 400px;
}

.search-btn {
  color: #495057;
  border: none;
  border-radius: 20px;
  background-color: whitesmoke;
}

.search-btn img {
  margin-left: 2rem;
}

.search-btn:hover {
  color: #495057;
  background-color: whitesmoke;
}

.search-btn:focus {
  color: #495057;
  background-color: whitesmoke;
  box-shadow: none;
}

.search-btn:active {
  color: #495057 !important;
  background-color: whitesmoke !important;
}

.modal-open .container-fluid,
.modal-open .container {
  -webkit-filter: blur(5px) grayscale(1%);
}

.modal-backdrop {
  opacity: 0.1 !important;
}

.navbar .form-group .nav-link {
  padding: 0.5rem 0.5rem;
}

.playVdo {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #ffffff77;
  text-align: center;
}

.playVdo img {
  width: 60%;
  margin-top: 20%;
  margin-left: 10%;
}

.starRate {
  color: gray;
  cursor: pointer;
  margin-bottom: 10px;
}

.starRate:hover {
  color: #f7b83c;
}

.show-xs {
  display: none;
}

.showXs {
  display: none;
}

.hidden-xs {
  display: block;
}

.hiddenXs {
  display: block;
}

.hiddenMd {
  display: block;
}

.socialHide-xs {
  display: inline-block;
}

.custom-dialog {
  margin-left: 20%;
  margin-right: 20%;
  max-width: 60%;
}

@media screen and (max-width: 768px) {
  .contentButton {
    font-size: 3vw;
  }

  .hidden-xs {
    display: none;
  }

  .hiddenXs {
    display: none;
  }

  .socialHide-xs {
    display: none;
  }

  .show-xs {
    display: block;
  }

  .showXs {
    display: block;
  }

  .App-header {
    padding-left: 1px;
    padding-right: 1px;
  }

  .content {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentBook {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentVdo {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentRecomend {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentLatest {
    padding-left: 1px;
    padding-right: 1px;
  }

  .custom-dialog {
    margin-left: 3%;
    margin-right: 5%;
    max-width: 90%;
  }
}

@media screen and (max-width: 1024px) {

  .hiddenMd {
    display: none;
  }

}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-toggler:focus {
  border: none;
  outline: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAAAAAAAA+UO7fwAAAAlwSFlzAAAASAAAAEgARslrPgAAAEtJREFUaN7t1aEBgDAQA8DScdigCzFUF2ID1qEWAQoR8Xc2JlFpDQCgru0r2M95p8s9XeN47drTxf4yAKA2T5xmAEBtnjjNAAAAchY1NQwWZXBS/gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNS0yN1QwODo1MDozMyswMDowMFdumlYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDUtMjdUMDg6NTA6MzMrMDA6MDAmMyLqAAAAKHRFWHRzdmc6YmFzZS11cmkAZmlsZTovLy90bXAvbWFnaWNrLTNQNnlyUkt6KnBAjQAAAABJRU5ErkJggg==);
}

#modal_loading {
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 144px);
}

#modal_loading .modal-content {
  background-color: transparent;
  border: none;
}

.carousel-inner {
  height: 100%;
}

/* .modalShare .modal-content{
  background-color: transparent;
  border: 1px solid #ffffff;
} */

.commentImg {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  overflow: hidden;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMJSURBVGhD7dnHihRBHMfxxYgimL2Y3kD0oOhBn0Ixe1O8ejI8gHowgArq+ggGzHcfwXQTMV5MqGAEw/cLszAU/56unqkeV5gffGDY6fpX72xNV9ixUUYZpUimYj2O4Coe4wN+dvj6EXzPa7x2Cv55luM4XuNPQ69g22UYehZhHD8Q3VwT1jgPaw4lO/Ae0c0M4i2s3Vpm4BKizku6gOkomtm4i6jDyBdcx8mOm52fRddG7sA+i8RPPvfmf+ME5iGNPzsNr4napm6gyF8id9h4Y7tQF6/J/SUcTgNlJ6LCET/53DisohqRregri/EOUdGU43sucuNw+oqoVsqn00I0jn++qGDE8do0txDVipxDozjDNpmkHBJNcwpRrYj3shTZcYqPClVp+xfQMWTFRZbrlKhIlX6G0G1Etaq8QNYCcAOiAr34hYye/VWZj9wvcbd1qI3L3ahxnbYeo90OoTbXEDWukzuR7UbuRJa6jNq48Yga5/DGXC6UWEpEHqI27p6ixk04sbmA80kjn/lNFnNVnFhrU2KT0hbvrTb//S9QYgi1JWsI+UWJGk8GD1Abjz6ixpNB1mO0n4nsO+7BCcqN+Wp4XDKrw9dr4Hs+lbzWNlGtXg6iNh46RY1THli5d92DJnuBidjGttawVtRHai1q44LpJaIC+oWLWIFSWQlrWjvqU9mLOePSNSryERvRVjbBPqK+jyI7jtloPshaTA0Y+0j79fvSaENjPO5LCx1A27GPtN+zaBw30umm/hnc7LeVJbCP7j7fYAH6ynZ0F5OTiR2VjjWtnfa3BQPFp0Na9ClWoVSsZc20nzMYOB4tuudNi3+DE4vv9xvbHkY0qdmn/zgpEg9aqzbhT7AXc5Abr92H6FOX+4dih7sT8aC112HXJ1zBfjibO6Zndvjan/me13xGVEM+caahtWyDx31R54Ow5mYMJT5iPe4rsflx/Pupe9Qy9Dhju+x4jujmerGNy4PGM2wbcZHloZPLANfsPs/9P5p/Ifn6PnzPJ5eryuyF2SijjFKVsbG/886Br2R0ojYAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.commentImg img {
  width: 100%;
  height: 100%;
  resize: both;
}

span.required{
  color: #ea4335;
}

#register-form div.modal-content {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

#register-form div.modal-header,
#register-form div.modal-body {
  background-color: white;
}

#register-form div.modal-body {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

#register-form div.modal-body {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

#ubirthdate {
  background-color: #f9f9f9;
}

#ubirthdate:focus {
  background-color: #ffffff;
}

#login-form div.modal-content {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

#login-form div.modal-header button.close {
  color: white;
  font-size: 2.5em;
  opacity: 1.0;
  padding-right: 0.1em;
}

#login-form div.modal-body {
  background-color: white;
  border-radius: .3rem;
}

.borderRadius {
  border-radius: 20px;
}

.background-gray {
  background-color: #f9f9f9;
}

.search-panel {
  background-image: url(/static/media/bg-book.293d443b.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.search-panel ::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 0.9em;
}

.search-panel :-ms-input-placeholder {
  color: #cccccc;
  font-size: 0.9em;
}

.search-panel ::-ms-input-placeholder {
  color: #cccccc;
  font-size: 0.9em;
}

.search-panel ::placeholder {
  color: #cccccc;
  font-size: 0.9em;
}

.search-panel .advanced-search {
  color: #777777;
}

.search-panel button {
  background-color: #0eb06a;
  border-color: #23c17d;
}

.search-result .tag-btn {
  background-color: #ffaea9;
  border: none;
  border-radius: 20px;
  padding: .2em 1.8em;
}

.search-result .card-text {
  font-size: 1.3em;
}

.popover {
  max-width: 30em;
}

.search-result-header {
  margin-top: 1rem;
  margin-left: 5px !important;
  margin-right: 5px !important;
  display: flex;
  flex-direction: column;
}

.search-result-header-inline {
  padding-bottom: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.search-sort-block {
  margin-right: 0;
  display: flex;
  justify-content: flex-start;
}

.search-result .search-body {
  display: flex;
  justify-content: flex-start;
}

.search-result .search-img {
  height: 100%;
  /* margin-right: .5rem; */
}

.search-result .search-detail {
  padding: 1em;
}

.checkBox input {
  border-radius: 50%;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
  width: 100%;
}

.facebook-login {
  color: whitesmoke;
  height: 2.4em;
  background-color: #3B5998;
  border-color: #4267b2;
  border: none;
}

.facebook-login:hover {
  background-color: #4267b2;
}


.facebook-login:focus {
  outline: none !important;
}

.facebook-login svg {
  margin-right: 1rem;
}

.google-login span {
  margin-left: 1rem;
}

.google-login {
  background-color: #DB4437;
  border: 1px solid #ea4335;
}

.google-login:hover {
  background-color: #ea4335;
  border: 1px solid #ea4335;
}
.microsoft-login {
  background-color: #0067b8;
  border: 1px solid #0067b8;
}

.microsoft-login:hover {
  background-color: #0067b8;
  border: 1px solid #0067b8;
}
#nav-notify {
  color: rgba(0, 0, 0, .5);
  padding: .5rem 0;
  cursor: pointer;
  position: relative;
}

#nav-notify:hover {
  color: rgba(0, 0, 0, .9);
}

#nav-notify .notify-badge {
  position: relative;
  right: -7px;
}

h4.advsea-md-title strong {
  margin-right: 1rem;
}

.notify-content p.topic {
  color: #999999;
  padding-bottom: .75em;
  margin-bottom: .25em;
  border-bottom: solid 2px #aaaaaa;
}

.notify-content div.media:not(:last-child) {
  border-bottom: solid 1px #aaaaaa;
  margin: 1em 0 1.5em 0;
}

.pagenotfound {
  margin: 12rem 0;
  width: 50%;
  color: #777777;
}

.notify-body .truncate {
  padding: .5rem;
  max-height: 5rem;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.notify-body .truncate.expand {
  max-height: unset !important;
}

/* .notify-body .truncate.unread {
  background-color: #efefef;
} */

/* #popover-contained div.media:not(:last-child) {
  border-bottom: solid 1px #cccccc;
  margin: .5em 0;
} */

@media screen and (max-width: 575.98px) {
  .content {
    padding-top: 0;
  }

  .search-panel {
    background: none;
  }

  .search-panel .card {
    border: none;
  }

  .search-panel .card .card-body {
    padding: 0;
    width: 100%;
  }

  .search-panel .card .card-body .row {
    padding: 1.25rem 1.5rem .5em 1.5rem;
  }

  .search-panel .card label.h4,
  .search-panel .card h4 {
    font-size: 0.8em;
  }

  .search-panel .card h4.advsea-md-title {
    padding-left: 1.5rem;
    background-color: #f9f9f9;
    margin-bottom: 0;
    padding-top: 1rem;
  }

  .advanced-search label.form-label {
    font-size: .9em;
  }

  .advanced-search div.row:nth-child(2) {
    padding-top: 0 !important;
  }

  .serach-btn-wrap {
    background-color: #f9f9f9;
    padding-top: 0 !important;
    margin: 0 !important;
  }

  #search-btn {
    float: none;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  .search-result-header {
    margin-bottom: 1em;
  }

  .search-result-header h5 {
    font-size: .9rem;
  }

  .search-sort-block {
    font-size: .9rem;
    display: flex;
    justify-content: flex-end;
    margin-right: .25em;
  }

  .search-sort-block div {
    top: -.25em;
  }

  .search-result {
    margin-left: 5px;
    width: 96.5%;
  }

  .search-result .search-img {
    width: 45%;
  }

  .search-result .search-detail {
    font-size: .7em;
  }

  .search-result .search-detail button {
    font-size: .9em;
    margin-bottom: .5em;
  }

  .paging-frame {
    width: 96.5%;
  }

  .facebook-login {
    font-size: .8em;
    padding-top: .8em;
    padding-bottom: .8em;
    height: 2.4rem;
  }

  .google-login {
    font-size: .8em;
    padding-top: .8em;
    padding-bottom: .8em;
    height: 2.4rem;
  }

  .google-login svg {
    margin-right: 1rem;
  }
  .microsoft-login {
    font-size: .8em;
    padding-top: .8em;
    padding-bottom: .8em;
    height: 2.4rem;
  }

  .microsoft-login svg {
    margin-right: 1rem;
  }
  .notify-content h4 {
    margin-top: .75em;
  }

}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .content {
    padding: 3% 1%;
  }

  .search-panel .card h4.advsea-md-title {
    display: block;
  }

  .advanced-search h4.advsea-xs-title {
    display: none;
  }

  #search-btn {
    margin-top: .7em;
  }

  .search-sort-block div {
    top: -.1em;
  }

  .search-result-header {
    /* margin-bottom: 1.2em; */
  }

  .search-result .search-img {
    width: 35%;
  }

  .search-result .search-detail {
    font-size: 1em;
    margin-top: .9em;
  }

  .search-result .search-detail button {
    font-size: 1em;
    margin-bottom: .9em;
  }

  #nav-notify .notify-badge {
    position: relative;

  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .search-panel .card h4.advsea-md-title {
    display: block;
  }

  .advanced-search h4.advsea-xs-title {
    display: none;
  }

  #search-btn {
    margin-top: .7em;
  }

  .search-result-header {
    font-size: 1em;
    /* margin-bottom: 1em; */
  }

  .search-sort-block div {
    top: -.15em;
  }

  .search-result .search-img {
    width: 25%;
    margin-right: 1em;
  }

  .search-result .search-detail {
    font-size: 1em;
    margin-top: .9em;
  }

  .search-result .search-detail button {
    font-size: 1em;
    margin-bottom: .9em;
  }

  #nav-notify .notify-badge {
    position: relative;

  }
}

@media (min-width: 992px) {
  .search-panel .card h4.advsea-md-title {
    display: block;
  }

  .advanced-search h4.advsea-xs-title {
    display: none;
  }

  #search-btn {
    margin-top: 1em;
  }

  .search-result-header h5 {
    font-size: 1.5rem;
  }

  .search-result-header {
    font-size: 1.2em;
    /* margin-bottom: 1.5em; */
  }

  .search-sort-block div {
    top: -.12em;
  }

  .search-result .search-img {
    width: 25%;
    margin-right: 1em;
  }

  .search-result .search-detail {
    font-size: 1em;
    margin-top: .9em;
  }

  .search-result .search-detail button {
    font-size: 1em;
    margin-bottom: 1em;
  }

  .notify-content h4 {
    margin-bottom: 1.5em;
  }

  #nav-notify .notify-badge {
    position: absolute;

  }

}
.headerProfile div.col-12 {
  margin-bottom: .5em;
}
.graph-stat .graph-boder {
  box-shadow:0 0 3px #e1e1e1;
}
.etc-stat div.col-12 {
  margin-top:35px;
  padding:20px;
  background-color:#f9f9f9;
  border-radius:10px;
}
.rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
  padding: .2em .5em;
}
.rating-info div.border-at-bottom {
  border-bottom: 1px solid #dcdcdc;
}

.rating-info div.media {
  padding-top: .5em;
  padding-bottom: .5em;
}
@media screen and (max-width: 575.98px) {
  .headerProfile img {
    width: 5rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.3em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
    margin-bottom: 1rem;
 }
 .headerProfile div.col-12:nth-child(6) {
   margin-bottom: 1.2em;
 }
 .headerProfile div.col-12:nth-child(7) {
   margin-bottom: 1.5em;
 }
 .headerProfile div.col-12:nth-child(8) a {
   font-size: 1em;
   padding: .2rem .5rem;
 }
 .headerProfile div.col-12:nth-child(9) a {
   font-size: 1em;
   padding: .3rem 1rem;

 }
  .graph-stat .graph-boder {
    box-shadow: none;
  }
  .etc-stat, .rating-info {
    padding: 0 .5em;
  }
  .etc-stat h6 {
    margin-top: .7em;
  }
  .etc-stat div.col-12 {
    margin-top:.5em;
    padding: 0 .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: .9em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .headerProfile img {
    width: 6rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.3em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
     margin-bottom: 1rem;
  }
  .headerProfile div.col-12:nth-child(6) {
    margin-bottom: 1.2em;
  }
  .headerProfile div.col-12:nth-child(7) {
    margin-bottom: 1.5em;
  }
  .headerProfile div.col-12:nth-child(8) a {
    font-size: 1em;
    padding: .2rem .5rem;
  }
  .headerProfile div.col-12:nth-child(9) a {
    font-size: 1em;
    padding: .3rem 1rem;

  }
  .graph-stat div:nth-child(2) {
    font-size: .9em;
  }
  .graph-stat .graph-boder {
    padding: 1em;
  }
  .etc-stat h6 {
    margin: .5em 0 .5em .5em;
  }
  .etc-stat div.col-12 {
    margin-top:1.3em;
    padding: .9em .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: 1em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  .rating-info div.media {
    padding-top: .7em;
    padding-bottom: .7em;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .headerProfile img {
    width: 6rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.3em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
    margin-bottom: 1rem;
 }
 .headerProfile div.col-12:nth-child(6) {
   margin-bottom: 1.2em;
 }
 .headerProfile div.col-12:nth-child(7) {
   margin-bottom: 1.5em;
 }
 .headerProfile div.col-12:nth-child(8) a {
   font-size: 1em;
   padding: .2rem .5rem;
 }
 .headerProfile div.col-12:nth-child(9) a {
   font-size: 1em;
   padding: .3rem 1rem;

 }
  .graph-stat div:nth-child(2) {
    font-size: .9em;
  }
  .graph-stat .graph-boder {
    padding: 1em;
  }
  .etc-stat h6 {
    margin: .5em 0 .5em .5em;
  }
  .etc-stat div.col-12 {
    margin-top:1.3em;
    padding: .9em .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: 1em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  .rating-info div.media {
    padding-top: .7em;
    padding-bottom: .7em;
  }
}

@media (min-width: 992px)  {
  .headerProfile img {
    width: 6rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.5em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
    margin-bottom: .8rem;
 }
 .headerProfile div.col-12:nth-child(6) {
   margin-bottom: 1.2em;
 }
 .headerProfile div.col-12:nth-child(7) {
   margin-bottom: 1.5em;
 }
 .headerProfile div.col-12:nth-child(8) a {
   font-size: 1em;
   padding: .2rem .5rem;
 }
 .headerProfile div.col-12:nth-child(9) a {
   font-size: 1em;
   padding: .3rem 1rem;

 }
  .graph-stat div:nth-child(2) {
    font-size: .9em;
  }
  .graph-stat .graph-boder {
    padding: 1em;
  }
  .etc-stat h6 {
    margin: .5em 0 .5em .5em;
  }
  .etc-stat div.col-12 {
    margin-top:1.3em;
    padding: .9em .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: 1.3em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  .rating-info div.media {
    padding-top: .7em;
    padding-bottom: .7em;
  }
}

.buttonGray {
  border-color: #d9d9d9;
}
.buttonGray:hover {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}
.buttonGray:active {
  border-color: #c2c2c2 !important;
  background-color: #a4a4a4 !important;
}
.buttonGreen {
  border-color: #15b789;
}
.buttonGreen:hover {
  border-color: #10946f;
  background-color: #10946f;
}
.buttonGreen:active {
  border-color: #10946f !important;
  background-color: #0d7054 !important;
}
.user-profile input.is-invalid, #change-password input.is-invalid {
  border-color: #dc3545;
}
.user-profile input.is-invalid:focus, #change-password input.is-invalid {
  outline-color:#dc3545;
  /* border-color: #dc3545; */
}

.upload-btn-wrapper {
  position: relative;
	overflow: hidden;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  height: 10vmax;
}

.upload-btn-wrapper:hover {
	cursor: pointer;
}

.profile-pic {
  height: 100%;
  width: 100%;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  
}
.profile-pic:after{
  /* font-family: FontAwesome;
  content: "\f007";
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 190px;
  background: #ecf0f1;
  color: #34495e;
  text-align: center; */
}

.upload-btn-wrapper .upload-btn {
  /* border: none; */
  opacity: 0.75;
  /* background-color: transparent; */
  color: #f5a81e;
  height: 100%;
  width: 100%;
  /* font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0; */
}

.upload-btn-wrapper .upload-btn svg{
  position: absolute;
  font-size: 2vmax;
  bottom: 5px;
  right: 0;
  text-align: center;
  opacity: .5;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  color: #f5a81e;
}

.upload-btn-wrapper:hover .upload-btn svg{
  opacity: 0.9 !important;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}
.forgotPwd-btn {
  color:#39c288;
  background:none;
  border:none !important;
}
.forgotPwd-btn:hover {
  color:#43f1a9;
  background: none;
}
.forgotPwd-btn:not(:disabled):not(.disabled):active {
  color:#34b982;
  background: none;
  border: none;
  outline: none !important;
  box-shadow: none !important;
}
.forgotPwd-btn:focus {
  outline: none;
  box-shadow: none;
}
@media screen and (max-width: 575.98px) {
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:.5em;
  }
  .user-profile div.col-12:nth-child(1) a {
    margin-left: .5em;
  }
  .user-profile .user-image {
    justify-content: center!important;
    padding-top: 1rem;
  }
  .user-profile .user-image img {
    width: 7rem;
    height: 7rem;
  }
  .edit-btn-group {
    display: flex;
    justify-content: center;
  }
  #change-password {
    margin-left: auto;
    margin-right: auto;
  }

  .upload-btn-wrapper .upload-btn {
    left: 20%;
    top: 45%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .edit-btn-group {
    display: flex;
    justify-content: center;
  }
  .user-profile .user-image {
    padding-top: 1rem;
  }
  .user-profile .user-image img {
    width: 7rem;
    height: 7rem;
  }
  .upload-btn-wrapper .upload-btn {
    left: 20%;
    top: 45%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:1.2em;
  }
  .edit-btn-group {
    display: flex;
    justify-content: center;
  }
  .user-profile .user-image {
    padding-top: 1rem;
  }
  .user-profile .user-image img {
    width: 9rem;
    height: 9rem;
  }
  .upload-btn-wrapper .upload-btn {
    left: 25%;
    top: 60%;
  }
}

@media (min-width: 992px)  {
  
  .user-profile div.col-12:nth-child(1) {
    margin-bottom:1.3em;
  }
  .user-profile .user-image{
    padding: 1.3em 0;
  }
  
  .user-profile .user-image img {
    width: 9rem;
    height: 9rem;
  }
}

.exam-history div.col-12:nth-child(2) {
  padding: 10px 10px 20px 10px; 
}
.exam-history div.col-12:nth-child(2) div.history-body {
  padding-left:3%;
  padding-right:3%;
}
.exam-score  {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}
.exam-score img {
  width:8vmin;
  height:8vmin;
  border-radius:4vmin;
}

@media screen and (max-width: 575.98px) {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:.5em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: .5em;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:0;
    padding-right:0;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: 1em 0 1em 0;
    border-radius: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:16vmin;
    height:16vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: center;
    font-size: 1.8rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top:.2rem;
    padding-left:.5rem;
    padding-right:1rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 2em;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: 0;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:3%;
    padding-right:3%;
    padding-bottom: 2em;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: .5em 0 .5em 0;
    border-radius:10px;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:8vmin;
    height:8vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: right;
    font-size: 1.3rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top:.2rem;
    padding-left:.5rem;
    padding-right:.5rem;
    margin-bottom: 0;
    width: 8rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 8vmin;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: 0;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:3%;
    padding-right:3%;
    padding-bottom: 2em;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: .5em 0 .5em 0;
    border-radius: 10px;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:8vmin;
    height:8vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: right;
    font-size: 1.3rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top: 0rem;
    padding-left:.5rem;
    padding-right:.5rem;
    margin-top: 8px;
    width: 9.25rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 8vmin;
  }
}

@media (min-width: 992px)  {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: 0;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:3%;
    padding-right:3%;
    padding-bottom: 2em;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: .5em 0 .1em 0;
    border-radius: 10px;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:8vmin;
    height:8vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: right;
    font-size: 1.2rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top: 0rem;
    padding-left:.5rem;
    padding-right:.5rem;
    width: 10.25rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 8vmin;
  }
}

