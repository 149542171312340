
span.required{
  color: #ea4335;
}

#register-form div.modal-content {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

#register-form div.modal-header,
#register-form div.modal-body {
  background-color: white;
}

#register-form div.modal-body {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

#register-form div.modal-body {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

#ubirthdate {
  background-color: #f9f9f9;
}

#ubirthdate:focus {
  background-color: #ffffff;
}

#login-form div.modal-content {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

#login-form div.modal-header button.close {
  color: white;
  font-size: 2.5em;
  opacity: 1.0;
  padding-right: 0.1em;
}

#login-form div.modal-body {
  background-color: white;
  border-radius: .3rem;
}

.borderRadius {
  border-radius: 20px;
}

.background-gray {
  background-color: #f9f9f9;
}

.search-panel {
  background-image: url("../img/bg-book.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.search-panel ::placeholder {
  color: #cccccc;
  font-size: 0.9em;
}

.search-panel .advanced-search {
  color: #777777;
}

.search-panel button {
  background-color: #0eb06a;
  border-color: #23c17d;
}

.search-result .tag-btn {
  background-color: #ffaea9;
  border: none;
  border-radius: 20px;
  padding: .2em 1.8em;
}

.search-result .card-text {
  font-size: 1.3em;
}

.popover {
  max-width: 30em;
}

.search-result-header {
  margin-top: 1rem;
  margin-left: 5px !important;
  margin-right: 5px !important;
  display: flex;
  flex-direction: column;
}

.search-result-header-inline {
  padding-bottom: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.search-sort-block {
  margin-right: 0;
  display: flex;
  justify-content: flex-start;
}

.search-result .search-body {
  display: flex;
  justify-content: flex-start;
}

.search-result .search-img {
  height: 100%;
  /* margin-right: .5rem; */
}

.search-result .search-detail {
  padding: 1em;
}

.checkBox input {
  border-radius: 50%;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
  width: 100%;
}

.facebook-login {
  color: whitesmoke;
  height: 2.4em;
  background-color: #3B5998;
  border-color: #4267b2;
  border: none;
}

.facebook-login:hover {
  background-color: #4267b2;
}


.facebook-login:focus {
  outline: none !important;
}

.facebook-login svg {
  margin-right: 1rem;
}

.google-login span {
  margin-left: 1rem;
}

.google-login {
  background-color: #DB4437;
  border: 1px solid #ea4335;
}

.google-login:hover {
  background-color: #ea4335;
  border: 1px solid #ea4335;
}
.microsoft-login {
  background-color: #0067b8;
  border: 1px solid #0067b8;
}

.microsoft-login:hover {
  background-color: #0067b8;
  border: 1px solid #0067b8;
}
#nav-notify {
  color: rgba(0, 0, 0, .5);
  padding: .5rem 0;
  cursor: pointer;
  position: relative;
}

#nav-notify:hover {
  color: rgba(0, 0, 0, .9);
}

#nav-notify .notify-badge {
  position: relative;
  right: -7px;
}

h4.advsea-md-title strong {
  margin-right: 1rem;
}

.notify-content p.topic {
  color: #999999;
  padding-bottom: .75em;
  margin-bottom: .25em;
  border-bottom: solid 2px #aaaaaa;
}

.notify-content div.media:not(:last-child) {
  border-bottom: solid 1px #aaaaaa;
  margin: 1em 0 1.5em 0;
}

.pagenotfound {
  margin: 12rem 0;
  width: 50%;
  color: #777777;
}

.notify-body .truncate {
  padding: .5rem;
  max-height: 5rem;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.notify-body .truncate.expand {
  max-height: unset !important;
}

/* .notify-body .truncate.unread {
  background-color: #efefef;
} */

/* #popover-contained div.media:not(:last-child) {
  border-bottom: solid 1px #cccccc;
  margin: .5em 0;
} */

@media screen and (max-width: 575.98px) {
  .content {
    padding-top: 0;
  }

  .search-panel {
    background: none;
  }

  .search-panel .card {
    border: none;
  }

  .search-panel .card .card-body {
    padding: 0;
    width: 100%;
  }

  .search-panel .card .card-body .row {
    padding: 1.25rem 1.5rem .5em 1.5rem;
  }

  .search-panel .card label.h4,
  .search-panel .card h4 {
    font-size: 0.8em;
  }

  .search-panel .card h4.advsea-md-title {
    padding-left: 1.5rem;
    background-color: #f9f9f9;
    margin-bottom: 0;
    padding-top: 1rem;
  }

  .advanced-search label.form-label {
    font-size: .9em;
  }

  .advanced-search div.row:nth-child(2) {
    padding-top: 0 !important;
  }

  .serach-btn-wrap {
    background-color: #f9f9f9;
    padding-top: 0 !important;
    margin: 0 !important;
  }

  #search-btn {
    float: none;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  .search-result-header {
    margin-bottom: 1em;
  }

  .search-result-header h5 {
    font-size: .9rem;
  }

  .search-sort-block {
    font-size: .9rem;
    display: flex;
    justify-content: flex-end;
    margin-right: .25em;
  }

  .search-sort-block div {
    top: -.25em;
  }

  .search-result {
    margin-left: 5px;
    width: 96.5%;
  }

  .search-result .search-img {
    width: 45%;
  }

  .search-result .search-detail {
    font-size: .7em;
  }

  .search-result .search-detail button {
    font-size: .9em;
    margin-bottom: .5em;
  }

  .paging-frame {
    width: 96.5%;
  }

  .facebook-login {
    font-size: .8em;
    padding-top: .8em;
    padding-bottom: .8em;
    height: 2.4rem;
  }

  .google-login {
    font-size: .8em;
    padding-top: .8em;
    padding-bottom: .8em;
    height: 2.4rem;
  }

  .google-login svg {
    margin-right: 1rem;
  }
  .microsoft-login {
    font-size: .8em;
    padding-top: .8em;
    padding-bottom: .8em;
    height: 2.4rem;
  }

  .microsoft-login svg {
    margin-right: 1rem;
  }
  .notify-content h4 {
    margin-top: .75em;
  }

}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .content {
    padding: 3% 1%;
  }

  .search-panel .card h4.advsea-md-title {
    display: block;
  }

  .advanced-search h4.advsea-xs-title {
    display: none;
  }

  #search-btn {
    margin-top: .7em;
  }

  .search-sort-block div {
    top: -.1em;
  }

  .search-result-header {
    /* margin-bottom: 1.2em; */
  }

  .search-result .search-img {
    width: 35%;
  }

  .search-result .search-detail {
    font-size: 1em;
    margin-top: .9em;
  }

  .search-result .search-detail button {
    font-size: 1em;
    margin-bottom: .9em;
  }

  #nav-notify .notify-badge {
    position: relative;

  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .search-panel .card h4.advsea-md-title {
    display: block;
  }

  .advanced-search h4.advsea-xs-title {
    display: none;
  }

  #search-btn {
    margin-top: .7em;
  }

  .search-result-header {
    font-size: 1em;
    /* margin-bottom: 1em; */
  }

  .search-sort-block div {
    top: -.15em;
  }

  .search-result .search-img {
    width: 25%;
    margin-right: 1em;
  }

  .search-result .search-detail {
    font-size: 1em;
    margin-top: .9em;
  }

  .search-result .search-detail button {
    font-size: 1em;
    margin-bottom: .9em;
  }

  #nav-notify .notify-badge {
    position: relative;

  }
}

@media (min-width: 992px) {
  .search-panel .card h4.advsea-md-title {
    display: block;
  }

  .advanced-search h4.advsea-xs-title {
    display: none;
  }

  #search-btn {
    margin-top: 1em;
  }

  .search-result-header h5 {
    font-size: 1.5rem;
  }

  .search-result-header {
    font-size: 1.2em;
    /* margin-bottom: 1.5em; */
  }

  .search-sort-block div {
    top: -.12em;
  }

  .search-result .search-img {
    width: 25%;
    margin-right: 1em;
  }

  .search-result .search-detail {
    font-size: 1em;
    margin-top: .9em;
  }

  .search-result .search-detail button {
    font-size: 1em;
    margin-bottom: 1em;
  }

  .notify-content h4 {
    margin-bottom: 1.5em;
  }

  #nav-notify .notify-badge {
    position: absolute;

  }

}