.headerProfile div.col-12 {
  margin-bottom: .5em;
}
.graph-stat .graph-boder {
  box-shadow:0 0 3px #e1e1e1;
}
.etc-stat div.col-12 {
  margin-top:35px;
  padding:20px;
  background-color:#f9f9f9;
  border-radius:10px;
}
.rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
  padding: .2em .5em;
}
.rating-info div.border-at-bottom {
  border-bottom: 1px solid #dcdcdc;
}

.rating-info div.media {
  padding-top: .5em;
  padding-bottom: .5em;
}
@media screen and (max-width: 575.98px) {
  .headerProfile img {
    width: 5rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.3em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
    margin-bottom: 1rem;
 }
 .headerProfile div.col-12:nth-child(6) {
   margin-bottom: 1.2em;
 }
 .headerProfile div.col-12:nth-child(7) {
   margin-bottom: 1.5em;
 }
 .headerProfile div.col-12:nth-child(8) a {
   font-size: 1em;
   padding: .2rem .5rem;
 }
 .headerProfile div.col-12:nth-child(9) a {
   font-size: 1em;
   padding: .3rem 1rem;

 }
  .graph-stat .graph-boder {
    box-shadow: none;
  }
  .etc-stat, .rating-info {
    padding: 0 .5em;
  }
  .etc-stat h6 {
    margin-top: .7em;
  }
  .etc-stat div.col-12 {
    margin-top:.5em;
    padding: 0 .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: .9em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .headerProfile img {
    width: 6rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.3em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
     margin-bottom: 1rem;
  }
  .headerProfile div.col-12:nth-child(6) {
    margin-bottom: 1.2em;
  }
  .headerProfile div.col-12:nth-child(7) {
    margin-bottom: 1.5em;
  }
  .headerProfile div.col-12:nth-child(8) a {
    font-size: 1em;
    padding: .2rem .5rem;
  }
  .headerProfile div.col-12:nth-child(9) a {
    font-size: 1em;
    padding: .3rem 1rem;

  }
  .graph-stat div:nth-child(2) {
    font-size: .9em;
  }
  .graph-stat .graph-boder {
    padding: 1em;
  }
  .etc-stat h6 {
    margin: .5em 0 .5em .5em;
  }
  .etc-stat div.col-12 {
    margin-top:1.3em;
    padding: .9em .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: 1em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  .rating-info div.media {
    padding-top: .7em;
    padding-bottom: .7em;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .headerProfile img {
    width: 6rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.3em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
    margin-bottom: 1rem;
 }
 .headerProfile div.col-12:nth-child(6) {
   margin-bottom: 1.2em;
 }
 .headerProfile div.col-12:nth-child(7) {
   margin-bottom: 1.5em;
 }
 .headerProfile div.col-12:nth-child(8) a {
   font-size: 1em;
   padding: .2rem .5rem;
 }
 .headerProfile div.col-12:nth-child(9) a {
   font-size: 1em;
   padding: .3rem 1rem;

 }
  .graph-stat div:nth-child(2) {
    font-size: .9em;
  }
  .graph-stat .graph-boder {
    padding: 1em;
  }
  .etc-stat h6 {
    margin: .5em 0 .5em .5em;
  }
  .etc-stat div.col-12 {
    margin-top:1.3em;
    padding: .9em .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: 1em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  .rating-info div.media {
    padding-top: .7em;
    padding-bottom: .7em;
  }
}

@media (min-width: 992px)  {
  .headerProfile img {
    width: 6rem;
  }
  .headerProfile div.col-12:nth-child(3) {
    font-size: 1.2em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(4) h4 {
    font-size: 1.5em;
    margin-bottom: 0rem;
  }
  .headerProfile div.col-12:nth-child(5) {
    margin-bottom: .8rem;
 }
 .headerProfile div.col-12:nth-child(6) {
   margin-bottom: 1.2em;
 }
 .headerProfile div.col-12:nth-child(7) {
   margin-bottom: 1.5em;
 }
 .headerProfile div.col-12:nth-child(8) a {
   font-size: 1em;
   padding: .2rem .5rem;
 }
 .headerProfile div.col-12:nth-child(9) a {
   font-size: 1em;
   padding: .3rem 1rem;

 }
  .graph-stat div:nth-child(2) {
    font-size: .9em;
  }
  .graph-stat .graph-boder {
    padding: 1em;
  }
  .etc-stat h6 {
    margin: .5em 0 .5em .5em;
  }
  .etc-stat div.col-12 {
    margin-top:1.3em;
    padding: .9em .5em;
  }
  .etc-stat div.col-12 h4 {
    font-size: 1.3em;
    margin-left: 1em;
  }
  .etc-stat div.col-12 label {
    margin-bottom: 0;
  }
  .rating-info {
    margin-top: 1em;
    margin-bottom: 0;
  }
  .rating-info .rating-info-header div:nth-child(2){
    font-size: .9em;
  }
  .rating-info .btHoverGreenActive, .rating-info .btHoverGreen {
    margin: 0;
  }
  .rating-info div.media {
    padding-top: .7em;
    padding-bottom: .7em;
  }
}
