.exam-history div.col-12:nth-child(2) {
  padding: 10px 10px 20px 10px; 
}
.exam-history div.col-12:nth-child(2) div.history-body {
  padding-left:3%;
  padding-right:3%;
}
.exam-score  {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}
.exam-score img {
  width:8vmin;
  height:8vmin;
  border-radius:4vmin;
}

@media screen and (max-width: 575.98px) {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:.5em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: .5em;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:0;
    padding-right:0;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: 1em 0 1em 0;
    border-radius: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:16vmin;
    height:16vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: center;
    font-size: 1.8rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top:.2rem;
    padding-left:.5rem;
    padding-right:1rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 2em;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: 0;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:3%;
    padding-right:3%;
    padding-bottom: 2em;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: .5em 0 .5em 0;
    border-radius:10px;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:8vmin;
    height:8vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: right;
    font-size: 1.3rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top:.2rem;
    padding-left:.5rem;
    padding-right:.5rem;
    margin-bottom: 0;
    width: 8rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 8vmin;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: 0;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:3%;
    padding-right:3%;
    padding-bottom: 2em;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: .5em 0 .5em 0;
    border-radius: 10px;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:8vmin;
    height:8vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: right;
    font-size: 1.3rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top: 0rem;
    padding-left:.5rem;
    padding-right:.5rem;
    margin-top: 8px;
    width: 9.25rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 8vmin;
  }
}

@media (min-width: 992px)  {
  .exam-history div.col-12:nth-child(1) {
    margin-bottom:1em;
  }
  .exam-history div.col-12:nth-child(1) a {
    margin-left: 0;
  }
  .exam-history div.col-12:nth-child(2) {
    padding: 0; 
  }

  .exam-history div.col-12:nth-child(2) div.history-body {
    padding-left:3%;
    padding-right:3%;
    padding-bottom: 2em;
  }
  div.history-body div.col-12:nth-child(1) {
    margin-top: 1em;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score {
    padding: .5em 0 .1em 0;
    border-radius: 10px;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score img {
    width:8vmin;
    height:8vmin;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-detail {
    margin-bottom: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade {
    text-align: right;
    font-size: 1.2rem;
    padding-left: 0;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label {
    padding-top: 0rem;
    padding-left:.5rem;
    padding-right:.5rem;
    width: 10.25rem;
  }
  .exam-history div.col-12:nth-child(2) div.history-body .exam-score .exam-grade label span {
    font-size: 8vmin;
  }
}
