@import "https://fonts.googleapis.com/css?family=Kanit&display=swap";
@import "screen/header.css";
@import "screen/footer.css";

@import "screen/contents/contents.css";

body {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
}

/* Override Bootrstrap */
a {
  color: #313131;
}

a:hover {
  color: #495057;
  text-decoration: none;
}


/* @import "../node_modules/video-react/dist/video-react.css";  */

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 3vmin;
  padding-bottom: 3vmin;
  width: 100%;
  margin: 0;
}

.contentBook {
  background-color: #42dfaf;
  background-image: url("img/bg-book.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.contentVdo {
  background-color: #f6fbff;
  background-image: url("img/video_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.contentRecomend {
  background-color: #7ad3ff;
  background-image: url("img/rec_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.contentLatest {
  background-color: #fdd5d2;
  background-image: url("img/latest_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.contentButton {
  width: 100%;
  border-radius: 1vmax;
  align-items: center;
  /* background-color: #42dfaf; */
  background-image: linear-gradient(to left, #cccccc, #c4c4c4);
  /* background-image: linear-gradient(to left,#40dfaf,#17b78a); */
  border: none;
  vertical-align: middle;
  padding-top: 1vmax;
  padding-bottom: 1vmax
}

.contentButton img {
  margin-top: 10%;
  width: 35%;
  height: auto;
  margin-bottom: 10%;
}

.contentButton:focus,
.contentButton.activefocus {
  box-shadow: none;
}

.contentButton.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.contentButton:hover {
  /* background-image: linear-gradient(to bottom left,#40dfaf,#17b78a); */
  background-image: linear-gradient(to bottom left, #40dfaf, #17b78a);
}

.contentButton.active {
  background-image: linear-gradient(to bottom left, #40dfaf, #17b78a);
}

.colCenter {
  text-align: center
}

.hitList {
  text-align: center;
  height: 160px;
  /* width: 150px; */
  /* margin: 5px; */
  overflow: hidden;
  padding: 0;
  position: relative;
}

.hitListText {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  background-color: #313131;
  opacity: 0.7;
  color: white;
  padding: 5px;
  text-align: left;
}

.borderIcon {
  background-color: #ffffff86;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 8px;
  margin: 0 auto;
}

.borderIcon img {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.borderIconRed {
  background-color: #efeff0;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 8px;
  margin: 0 auto;
}

.borderIconRed img {
  background-color: #ff5759;
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.borderIconBlue {
  background-color: #1d7be04d;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 8px;
  margin: 0 auto;
}

.borderIconBlue img {
  background-color: #1d7be0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ribbon2 {
  width: 40px;
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  border-top-left-radius: 3px;
  background: #1d7be0;
  /* background-image: linear-gradient(to top left,#1d7be0,#2d87e7); */
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  z-index: 5;
}

.ribbon2:before,
.ribbon2:after {
  content: "";
  position: absolute;
}

.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -16px;
  left: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 17px solid #1d7be0;
}

@import "/node_modules/video-react/dist/video-react.css";

.buttonFilter {
  padding: 1;
  background-color: #fec35c;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
  border: none;
}

.buttonFilter:hover {
  background-color: #f3a31a;
}

.buttonFilter:active {
  background-color: #fec35c !important;
  box-shadow: 0 0 0 0.2rem rgba(245, 182, 46, 0.5)
}

.buttonFilter:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 182, 46, 0.5)
}

.buttonFilter img {
  height: 20px;
}

.buttonSort {
  padding: 1;
  text-align: center;
  background-color: #ffffff;
  color: #787a7a;
  border-radius: 20px;
  font-size: 14px;
  border: none;
}

.buttonSort:hover {
  background-color: #faca77;
  color: #ffffff;
  border: none;
}

.buttonSort:focus {
  background-color: #fec35c;
  color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.buttonSortActive {
  padding: 1;
  text-align: center;
  background-color: #fec35c;
  color: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  border: none;
}

.buttonSortActive:hover {
  background-color: #fec35c;
  color: #000000;
  border: none;
}

.buttonSortActive:focus {
  background-color: #fec35c;
  color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}

.imgList {
  margin-bottom: 20px;
}

.imgList img {
  width: 100%;
}

.imgList div:hover {
  box-shadow: 0 0 3px #7a7a7a;
}

.buttonPage {
  padding: 1;
  text-align: center;
  background-color: #ffffff;
  color: #787a7a;
  font-size: 14px;
  border: 1px solid #787a7a;
}

.buttonPage:hover {
  background-color: #fec35c;
  color: #ffffff;
}

.buttonPage:focus {
  background-color: #fec35c;
  color: #ffffff;
  box-shadow: none;
}

.buttonPageActive {
  padding: 1;
  text-align: center;
  background-color: #fec35c;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #787a7a;
}

.buttonPageActive:hover {
  background-color: #fec35c;
  color: #ffffff;
}

.buttonPageActive:focus {
  background-color: #fec35c;
  color: #ffffff;
  box-shadow: none;
}

.checkBox {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.checkBox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 18px;
  width: 18px;
  transition: all 0.12s ease-out 0s;
  background: #fff;
  border: 1px solid #cbd1d8;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  /* z-index: 1000; */
  /* border-radius: 50%; */
}

.checkBox input:hover {
  background: #9faab7;
}

.checkBox input:checked {
  background: #07b169;
}

.checkBox input:checked::before {
  height: 18px;
  width: 18px;
  position: absolute;
  content: '✓';
  display: inline-block;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin-left: 0;

}

.checkBox input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #07b169;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}

.nav-tabs .nav-link {
  color: #9faab7;
  /* font-size: 2vmin; */
}

.nav-tabs .nav-link.active {
  color: #07b169;
  background-color: #fff;
  border-color: #fff #fff #07b169;
  border-bottom-width: 5px;
}


.radiusGreen {
  padding: 5px 10px;
  background-color: #07b169;
  box-shadow: 1px 1px 2px #dfdfdf;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.radiusGreen:hover {
  box-shadow: 0 0 5px #0cd682;
}

.radiusGreen:active {
  box-shadow: inset 0 0 5px #0cd682;
}

.radiusGreenThin {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #2ac79a;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  /* font-size: 90%; */
  white-space: nowrap;
}

.outlineGreen {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #07b169;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #07b169;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  font-size: 90%;
  white-space: nowrap;
}

.radiusPink {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffb0ab;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusOrange {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #fec35c;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusBlue {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #7ad3ff;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusPurple {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #cc66ff;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusFlash {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffc3ab;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusApp {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #fe6d6b;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusWhite {
  padding: 5px 10px;
  margin: 0 2px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 20px;
  box-shadow: 1px 1px 2px #dfdfdf;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.radiusWhite:hover {
  box-shadow: 0 0 5px #a6a8a8;
}

.radiusWhite:active {
  box-shadow: inset 0 0 5px #a6a8a8;
}

.radiusGray {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #e1e1e1;
  color: #ff4546;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.radiusReadOnly {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #e1e1e1;
  color: #bfbfbf;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
  cursor: no-drop;
  white-space: nowrap;
}

.radiusStarOrange {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffefca;
  color: #ff8c20;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  /* cursor: pointer; */
  white-space: nowrap;
}

.radiusGrayLinear {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #e1e1e1;
  background-image: linear-gradient(to right, #e1e1e1, #bdbdbd);
  color: #ff4546;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  cursor: pointer;
  white-space: nowrap;
}

.radiusGrayLinear:hover {
  background-image: linear-gradient(to bottom, #e1e1e1, #bdbdbd);
}

.radiusGrayLinear:active {
  background-image: linear-gradient(to top, #e1e1e1, #bdbdbd);
}

.radiusGreenLinear {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #40dfaf;
  background-image: linear-gradient(to right, #40dfaf, #17b78a);
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  /* font-size: 1vmax; */
  cursor: pointer;
  white-space: nowrap;
}

.radiusGreenLinear:hover {
  background-image: linear-gradient(to bottom, #40dfaf, #17b78a);
}

.radiusGreenLinear:active {
  background-image: linear-gradient(to top, #40dfaf, #17b78a);
}

.carousel-caption {
  top: 10%;
  left: 3%;
  right: unset;
  bottom: unset;
  text-align: left;
  border-left: 10px solid #ffffff;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: unset;
  z-index: 0;
}

.carousel-caption h3 {
  font-size: 6vmin;
}

.carousel-item img {
  width: 100%;
}

.carousel-indicators li {
  height: 2vmin;
  width: 2vmin;
  border-radius: 2vmin;
  background: #ffffff;
  border: none;
  opacity: 0.5;
  box-shadow: 0 0 5px #929191;
}

.carousel-indicators li:active {
  opacity: 1;
}

.selectList {
  /* border-bottom: 1px solid #cccccc; */
  width: 100%;
  /* padding-bottom: 5px; */
}

.buttonRed {
  padding-left: 3vmax;
  padding-right: 3vmax;
  padding-top: 0.7vmax;
  padding-bottom: 0.7vmax;
  background-color: #fed7d8;
  color: #fe7776;
  text-align: center;
  border-radius: 5px;
  /* font-size: 1.5vmin; */
  cursor: pointer;
  border-color: #fe7776;
}

.buttonBlue {
  padding-left: 3vmax;
  padding-right: 3vmax;
  padding-top: 0.7vmax;
  padding-bottom: 0.7vmax;
  background-color: #daebff;
  color: #4891e3;
  text-align: center;
  border-radius: 5px;
  /* font-size: 1.5vmin; */
  cursor: pointer;
  border-color: #4891e3;
}

.buttonWhite {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #ffffff;
  color: #ff5857;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.buttonGreen {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #15b789;
  color: #ffffff;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.buttonGray {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #d9d9d9;
  color: #ffffff;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.buttonThin {
  padding-left: 0.8vmax;
  padding-right: 0.8vmax;
  padding-top: 0.2vmax;
  padding-bottom: 0.2vmax;
  background-color: #0000001e;
  color: #ffffff;
  text-align: center;
  border-radius: 2vmax;
  /* font-size: 0.8vmax; */
  cursor: pointer;
}

.headerProfile {
  background-color: #36d4a5;
  padding: 20px;
  background-image: url("img/bg-book.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff
}

.btHoverGreen {
  margin-right: 20px;
  color: #9d9d9d;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.btHoverGreenActive {
  background-color: #dcfaee;
  color: #07b169;
  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.btHoverGreen:hover {
  background-color: #dcfaee;
  color: #07b169;
}

.inputProfile {
  width: 100%;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
}

.modal-sm {
  max-width: 400px;
}

.search-btn {
  color: #495057;
  border: none;
  border-radius: 20px;
  background-color: whitesmoke;
}

.search-btn img {
  margin-left: 2rem;
}

.search-btn:hover {
  color: #495057;
  background-color: whitesmoke;
}

.search-btn:focus {
  color: #495057;
  background-color: whitesmoke;
  box-shadow: none;
}

.search-btn:active {
  color: #495057 !important;
  background-color: whitesmoke !important;
}

.modal-open .container-fluid,
.modal-open .container {
  -webkit-filter: blur(5px) grayscale(1%);
}

.modal-backdrop {
  opacity: 0.1 !important;
}

.navbar .form-group .nav-link {
  padding: 0.5rem 0.5rem;
}

.playVdo {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #ffffff77;
  text-align: center;
}

.playVdo img {
  width: 60%;
  margin-top: 20%;
  margin-left: 10%;
}

.starRate {
  color: gray;
  cursor: pointer;
  margin-bottom: 10px;
}

.starRate:hover {
  color: #f7b83c;
}

.show-xs {
  display: none;
}

.showXs {
  display: none;
}

.hidden-xs {
  display: block;
}

.hiddenXs {
  display: block;
}

.hiddenMd {
  display: block;
}

.socialHide-xs {
  display: inline-block;
}

.custom-dialog {
  margin-left: 20%;
  margin-right: 20%;
  max-width: 60%;
}

@media screen and (max-width: 768px) {
  .contentButton {
    font-size: 3vw;
  }

  .hidden-xs {
    display: none;
  }

  .hiddenXs {
    display: none;
  }

  .socialHide-xs {
    display: none;
  }

  .show-xs {
    display: block;
  }

  .showXs {
    display: block;
  }

  .App-header {
    padding-left: 1px;
    padding-right: 1px;
  }

  .content {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentBook {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentVdo {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentRecomend {
    padding-left: 1px;
    padding-right: 1px;
  }

  .contentLatest {
    padding-left: 1px;
    padding-right: 1px;
  }

  .custom-dialog {
    margin-left: 3%;
    margin-right: 5%;
    max-width: 90%;
  }
}

@media screen and (max-width: 1024px) {

  .hiddenMd {
    display: none;
  }

}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-toggler:focus {
  border: none;
  outline: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("img/menu.png");
}

#modal_loading {
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 144px);
}

#modal_loading .modal-content {
  background-color: transparent;
  border: none;
}

.carousel-inner {
  height: 100%;
}

/* .modalShare .modal-content{
  background-color: transparent;
  border: 1px solid #ffffff;
} */

.commentImg {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  overflow: hidden;
  background-image: url('img/male-user-48.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.commentImg img {
  width: 100%;
  height: 100%;
  resize: both;
}