.footer  {
  padding: 2em auto;
}
.footer .jumbotron {
  border-radius: 2px;
}
.img-w {
  width: 20%;
}
.img-margin-right {
  margin-right: 10px;
  margin-bottom: 10px;
}
#dl-chn-head {
  margin-bottom: .75em;
}
.about-us {
  width: 100%;
}
.about-us .list-group {
  list-style-type: none;
  font-size: .95em;
}
.about-us .list-group .obec-brand {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}
.about-us .list-group .obec-brand img {
  padding-right: 1em;
  height: 100px;
}
.about-us .list-group .obec-brand div {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-self: center;
}
.about-us .site-map p, .about-us .contact-us p, .about-us .facebook-fanpage p {
  font-weight: bold;
}
.about-us .site-map li {
  margin-bottom: .52rem;
}
.about-us .contact-us li img {
  margin-right: .9em;
}
.contact-us ul li span {
  margin-bottom: .5rem;
}
@media screen and (max-width: 575.98px) {
  .about-us {
    /* margin-left: 2em !important; */
  }
  .about-us .list-group .obec-brand img {
    height: 120px;
  }
  .about-us .obec-info, .about-us .contact-us, .about-us .facebook-fanpage {
    margin-bottom: 2em;
  }
  .about-us .contact-us, .about-us .facebook-fanpage  {
    margin-left: 1rem;
  }
  .about-us .contact-us li img {
    margin-right: 3em;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .about-us {
    margin-left: 2em !important;
  }
  .about-us .list-group .obec-brand img {
    height: 120px;
  }
  .about-us .obec-info, .about-us .contact-us, .about-us .facebook-fanpage {
    margin-bottom: 2em;
  }
  .about-us .contact-us, .about-us .facebook-fanpage  {
    margin-left: 1rem;
  }
  .about-us .contact-us li img {
    margin-right: 3em;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .about-us .site-map, .about-us .facebook-fanpage  {
    display: none;
  }
  .about-us {
    margin-left: 1em !important;
    padding-bottom: 3em;
  }
}

@media (min-width: 992px)  {
  .about-us {
    /* margin-left: 1em !important; */
    padding-bottom: 2em;
  }
}

.footer .copyright{
  background-color: #c9c9c9;
  color: #333;
}
