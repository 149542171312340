.navbar .navbar-collapse {
  margin-left: .5em;
}

@media screen and (max-width: 575.98px) {
  #login-form .modal-body{
    padding: 1em;
  }
  .navbar .navbar-brand  {
    margin: .75em 0 .75em .25em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .navbar .navbar-brand  {
    margin: .5em 0 .5em .5em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .navbar .navbar-brand  {
    margin: .5em 0 .5em .5em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
}

@media (min-width: 992px)  {
  .navbar .navbar-brand img {
    height: 60px;
  }

  #basic-navbar-nav {
    margin-left: 3em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #basic-navbar-nav form.form-inline .form-group {
    display: flex;
    justify-content: space-between;
  }
  #basic-navbar-nav form.form-inline .form-group .search-btn{
    width: 10em;
    display: flex;
    justify-content: space-between;
  }
  #basic-navbar-nav form.form-inline .form-group .btn-toolbar {

  }
}


@media (min-width: 1400px)  {
  .navbar .navbar-brand  {
    margin: .5em 0 .5em .5em;
  }
  .navbar .navbar-brand img {
    height: 40px;
  }
  
  #basic-navbar-nav {
    margin-left: 7em;
  }
  #basic-navbar-nav form.form-inline .form-group .search-btn{
    width: 12em;
  }
  
}
