.contentActiveBar {
  background-color: rgb(64, 223, 175);
  height: 8px;
}

.contents-list a .card-text {
  color: black;
}

.contents-list a,
.contents-list a:hover {
  text-decoration: none;
}

.card-text {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 20px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
}

.imgOrg {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
}

@media screen and (device-aspect-ratio: 2/3) {
  .imgType1 {
    height: 191px !important;
  }

  .imgType3 {
    height: 116px !important;
  }
}

/* iphone 5/5s */
@media screen and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  .imgType1 {
    height: 153px !important;
  }

  .imgType3 {
    height: 86px !important;
  }
}

@media screen and (device-aspect-ratio: 71/40) and (orientation: landscape) {
  .imgType1 {
    height: 319px !important;
  }

  .imgType3 {
    height: 179px !important;
  }
}

/* iphone 6/7/8 */
@media screen and (device-aspect-ratio: 375/667) and (orientation: portrait) {
  .imgOrg {
    height: 143px !important;
  }

  .imgType1 {
    height: 191px !important;
  }

  .imgType3 {
    height: 107px !important;
  }
}

@media screen and (device-aspect-ratio: 667/375) and (orientation: landscape) {
  .imgOrg {
    height: 105px !important;
  }

  .imgType1 {
    height: 140px !important;
  }

  .imgType3 {
    height: 79px !important;
  }
}

/* iphone 6/7/8 plus */
@media screen and (device-aspect-ratio: 414/736) and (orientation: portrait) {
  .imgType1 {
    height: 216px !important;
  }

  .imgType3 {
    height: 116px !important;
  }
}

@media screen and (device-aspect-ratio: 736/414) and (orientation: landscape) {
  .imgType1 {
    height: 140px !important;
  }

  .imgType3 {
    height: 79px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 575.9px) and (orientation: portrait) {
  .imgOrg {
    height: 115px;
  }

  .imgType1 {
    height: 263px;
  }

  .imgType3 {
    height: 148px;
  }
}

@media screen and (max-width: 575.9px) and (orientation: landscape) {
  .imgOrg {
    height: 239px;
  }

  .imgType1 {
    height: 263px;
  }

  .imgType3 {
    height: 148px;
  }
}

@media (min-width: 576px) and (max-width: 767.9) {
  .imgType1 {
    height: 140px !important;
  }

  .imgType3 {
    height: 79px !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.9px) {
  .imgType1 {
    height: 120px !important;
  }

  .imgType3 {
    height: 68px !important;
  }
}

/* Large devices (desktops, 992px and up) */
/* iPad Pro 1024x1366 */
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .imgOrg {
    height: 130px !important;
  }

  .imgType1 {
    height: 173px !important;
  }

  .imgType3 {
    height: 98px !important;
  }
}

/* iPad Pro 1366x1024 */
@media screen and (min-width: 1025px) and (max-width: 1199.9px) {
  .imgOrg {
    height: 130px !important;
  }

  .imgType1 {
    height: 173px !important;
  }

  .imgType3 {
    height: 98px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1365.9px) {
  .imgOrg {
    height: 130px !important;
  }

  .imgType1 {
    height: 213px !important;
  }

  .imgType3 {
    height: 120px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1366px) {
  .imgOrg {
    height: 160px !important;
  }

  .imgType1 {
    height: 213px !important;
  }

  .imgType3 {
    height: 120px !important;
  }
}